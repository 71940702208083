<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.userDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-card class="pa-2">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row wrap no-gutters justify="space-between" class="pb-1">
                <v-col
                  cols="12"
                  sm="9"
                  class="pr-sm-1"
                >
                  <v-text-field
                    v-model="user.name"
                    outlined
                    dense
                    :label="$lang.labels.name"
                    required
                    data-cy="user-name"
                    class="required-asterisk"
                    :rules="[v => !!v || 'Required!']"
                    :persistent-hint="isEdit"
                    :hint="formatHint"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                  <v-btn
                    class="button-default-width"
                    color="primary"
                    @click="changeStatus()"
                  >
                    {{ user.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row wrap no-gutters class="pb-4">
                <v-col cols="12">
                  <v-text-field
                    v-model="user.email"
                    outlined
                    dense
                    :label="$lang.labels.email"
                    required
                    data-cy="user-email"
                    class="required-asterisk"
                    :rules="[v => !!v || 'Required!']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-4">
                  <v-btn
                    color="accent"
                    class="color-accent-text"
                    @click="resendPassword()"
                  >
                    {{ $lang.actions.resetPassword }}
                  </v-btn>
                  <v-btn
                    v-if="isEdit && currentUser && user && (currentUser.id === user.id || currentUserRoles.includes('USER_CREATOR'))"
                    class="ml-2 color-accent-text"
                    color="primary"
                    data-cy="create-user"
                    @click="generateKey()"
                  >
                    {{ $lang.actions.generateApiKey }}
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <user-roles-select
                    :role="user.roles"
                    :options="roles"
                    data-cy="user-role"
                    @changeRoles="user.roles = $event"
                  ></user-roles-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="lastLoginFormatted"
                    outlined
                    dense
                    readonly
                    :label="$lang.labels.lastLogin"
                    data-cy="user-lastLogin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <v-btn
                    class="button-default-width"
                    color="secondary"
                    :to="{ name: 'users', params: { lang: $lang.current_lang } }"
                  >
                    <v-icon
                      right
                      dark
                      class="ma-0"
                    >
                      mdi-arrow-left
                    </v-icon>
                    <span class="ml-1">{{ $lang.actions.back }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <div class="d-flex justify-end">
                    <div class="mr-5">
                      <v-btn
                        icon
                        color="primary"
                        class="ml-2"
                        style="margin-top: 2px"
                        title="copy"
                        @click="copyFields()"
                      >
                        <v-icon>mdi-clipboard-outline</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        style="margin-top: 2px"
                        title="paste"
                        class="ml-1"
                        @click="pasteFields()"
                      >
                        <v-icon>mdi-clipboard</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        :disabled="!valid || lock || !user.roles || !user.roles.length"
                        color="success"
                        data-cy="submit-user"
                        class="button-default-width"
                        @click="submit()"
                      >
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-if="apiKeyShow" v-model="apiKeyShow" max-width="30%">
        <v-card class="pa-2">
          <v-card-title class="pb-2">
            <span>{{ $lang.header.apiKey }}</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              color="primary"
              text
              large
              @click="apiKeyShow = false"
            >
              X
            </v-btn>
          </v-card-title>
          <v-row wrap no-gutters class="pb-4">
            <v-col
              cols="9"
              class="pr-sm-1"
            >
              <v-text-field
                v-model="apiKey"
                outlined
                dense
                :label="$lang.labels.apiKey"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
            >
              <v-btn
                text
                color="primary"
                @click="copyApiKey()"
              >
                {{ $lang.actions.copyApiKey }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-btn
              color="secondary"
              text
              @click="apiKeyShow = false"
            >
              {{ $lang.actions.back }}
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import {
  disableUserUsingGET as disableUser,
  enableUserUsingGET as enableUser,
  getUserByIdUsingGET as getUser,
  updateUserUsingPUT as updateUser,
  createUserUsingPOST as createUser,
  getRolesUsingGET as getRoles,
  resendRegisterTokenUsingPOST as resendToken,
  generateApiKeyUsingPUT as generateApiKey, getSettingsUsingGET as getSettings
} from '@/utils/api'
import copy from 'copy-to-clipboard'
import UserRolesSelect from '../../components/ui/UserRolesSelect'

export default {
  components: {
    UserRolesSelect
  },
  data() {
    return {
      apiKeyShow: false,
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: false,
      lock: false,
      user: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        email: '',
        status: '',
        roles: [],
        lastLogin: ''
      },
      roles: [],
      currentUser: null,
      currentUserRoles: [],
      apiKey: ''
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.user.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.user.modifiedOn)}, ID: ${this.user.id}`
    },
    lastLoginFormatted() {
      return this.$options.filters.formatDateTime(this.user.lastLogin)
    }
  },
  created() {
    this.isEdit = this.$route.name === 'userEdit'

    if (localStorage.userData) {
      this.currentUser = JSON.parse(localStorage.userData)
      this.currentUserRoles = this.currentUser.roles.map((x) => x.name)
    }

    getRoles()
      .then((res) => {
        this.roles = res.data.data.items.filter((x) => x.isAssignableToUser)
      })
      .catch((err) => {
        this.err = err
      })

    if (this.isEdit && this.$route.params.id) {
      this.loading = true
      getUser({ id: this.$route.params.id })
        .then((res) => {
          this.user = res.data.data

          this.user.roles = res.data.data.roles.map((x) => x.id)

          this.loading = false
        })
        .catch((err) => {
          this.err = err
        })
    }
  },
  methods: {
    copyFields() {
      localStorage.setItem('copiedUserRoleData', JSON.stringify(this.user.roles))
    },
    pasteFields() {
      this.user.roles = JSON.parse(localStorage.getItem('copiedUserRoleData'))
    },
    copyApiKey() {
      if (this.apiKey) {
        copy(this.apiKey)

        this.success = this.$lang.success.copiedClipboard
        this.apiKeyShow = false

        setTimeout(() => this.success = '', 5000)
      }
    },
    async generateKey() {
      this.lock = true

      let res = null

      const { id } = this.user

      try {

        res = await generateApiKey({ id })

        if (res && res.status !== 200) {
          this.err = this.$lang.errors.apiKeyGeneration
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }

        this.apiKey = res.data.data.apiKey

        this.success = this.$lang.success.apiKeyGeneration
        setTimeout(() => this.success = '', 5000)

        if (this.isEdit && this.currentUser && this.currentUser.id === this.user.id) {
          localStorage.userData = JSON.stringify({ ...this.currentUser, ...res.data.data })
        }
        this.apiKeyShow = true

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async submit() {
      this.lock = true

      let res = null

      const { id } = this.user

      if (!this.isEdit) {
        delete this.user.id
        this.user.status = 'ACTIVE'
      }
      delete this.user.createdOn
      delete this.user.modifiedOn

      try {

        res = this.isEdit ? await updateUser({ id, request: this.user }) : await createUser({ request: this.user })

        if (res && res.status !== 200) {
          this.err = this.isEdit ? this.$lang.errors.userUpdate : this.$lang.errors.userCreate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.userUpdated : this.$lang.success.userCreated

        if (this.isEdit && this.currentUser && this.currentUser.id === this.user.id) {
          getUser({ id: this.$route.params.id })
            .then((res) => {
              localStorage.userData = JSON.stringify({ ...res.data.data, authToken: this.currentUser.authToken })

              this.$router.push({
                name: 'users',
                params: {
                  message: this.success
                }
              })
            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.$router.push({
            name: 'users',
            params: {
              message: this.success
            }
          })
        }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async resendPassword () {
      this.lock = true
      try {
        const res = await resendToken({ id: this.user.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.resendPassword
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.$lang.success.resendPassword

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.user.status === 'ACTIVE' ? await disableUser({ id: this.user.id }) : await enableUser({ id: this.user.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.userStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.user.status !== 'ACTIVE' ? this.$lang.success.userActivated : this.$lang.success.userDeactivated

        this.user.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
